import { BaseService } from '../api';
import { PET_URL } from './constants';

//TODO: All this formToModel and modelToForm logic is very similar to the ones used on steps.js.
//We should dedupe them
export const formToModel = pet => ({
  name: pet.name,
  gender: pet.gender,
  breeds: pet.breeds.map(({ value }) => value),
  allergies: pet.allergies.map(({ value }) => value),
  weight: pet.weight,
  ideal_weight: pet.idealWeight,
  activity_level: pet.activity,
  is_neutered: pet.spayedStatus,
  birth_date: `${pet.year}-${pet.month}-${pet.day || '01'}`,
  recommendation_version: pet.recommendationVersion,
});

export const modelToForm = pet => {
  const [year, month, day] = pet.birth_date?.split('-') ?? ['', '', ''];
  return {
    name: pet.name,
    gender: pet.gender,
    weight: pet.weight,
    idealWeight: pet.ideal_weight,
    activity: pet.activity_level,
    spayedStatus: `${pet.is_neutered}`,
    year: year,
    month: month,
    day: day,
    breeds:
      pet.breeds?.map(breed => ({
        value: breed.id,
        label: breed.name,
      })) ?? [],
    allergies:
      pet.allergies?.map(({ id, name }) => ({
        name,
        label: name,
        value: id,
      })) ?? [],
  };
};

export class PetService extends BaseService {
  list = () => this.client.get(`${PET_URL}`);

  create = async payload => await this.client.post(PET_URL, payload);

  update = (petId, payload) =>
    this.client.patch(`${PET_URL}${petId}/`, payload);

  delete = petId => this.client.delete(`${PET_URL}${petId}/`);
}
